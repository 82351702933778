import React from "react";
import {OperatorOutlet} from "@/model/Log";
import moment from "moment/moment";
import {cn} from "@/lib/utils";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Badge} from "@/components/ui/badge";

interface MailDisplayProps {
    operatorOutlet: OperatorOutlet
}

export function OperatorOutletDetail({ operatorOutlet }: MailDisplayProps) {

    return (
        <ScrollArea className="flex h-full flex-col p-4">
            <div className="flex flex-1 flex-col">
                <div className="gap-4 text-sm mb-4 px-2">
                    <div className="grid gap-1">
                        <div className='flex items-center'>
                            <div className="font-semibold grow">{operatorOutlet.operatorName}</div>
                            <span className='text-xs'>
                                {moment.utc(operatorOutlet.endedAt).diff(moment.utc(operatorOutlet.startedAt), 'milliseconds').toLocaleString("ko-KR")}ms
                                </span>

                        </div>
                        <div className="line-clamp-1 text-xs">{operatorOutlet.type}</div>
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    {operatorOutlet.prompts?.map((prompt, index) => (
                        <div
                            key={`prompt-${index}`}
                            className={cn(
                                "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                            )}
                        >
                            <div className='flex items-center'>
                                <div className="font-semibold">Prompt</div>
                                <Badge variant={"outline"} className="ml-2">
                                    {prompt.role}
                                </Badge>
                            </div>

                            <div>
                                <p className="flex-1 whitespace-pre-wrap text-muted-foreground p-1">
                                    {prompt.content}
                                </p>
                            </div>


                        </div>
                    ))}
                    <div
                        className={cn(
                            "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                        )}
                    >
                        <div className="font-semibold">Outlet</div>
                        <div className="flex-1 whitespace-pre-wrap text-muted-foreground">
                            {JSON.stringify(operatorOutlet.outlet, null, 2)}
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
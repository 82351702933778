import {SearchEngineType} from "../searchSpecification";

export interface Agent {
    llmId: string
    promptId: string
    llmType: LLMType
}

export interface CommonSetting {
    welcomeAgent: Agent
    analyzeKoreanAgent: Agent
    analyzeEnglishAgent: Agent
    passageAnswerAgent: Agent
    contextAnswerAgent: Agent
    contextQuestionAgent: Agent
    welcomeQuestionAgent: Agent
    channelCuratingAgent: Agent
}

export interface ADASetting extends CommonSetting {
    type: SearchEngineType.ADA
}

export interface Qa42Setting extends CommonSetting {
    type: SearchEngineType.QA42
    mrcAnswerAgent: Agent
}

export enum PromptType {
    // 요약 Summary
    WELCOME = 'welcome',  // 월컴메세지 생성

    // 질의 분석 Analyze
    ANALYZE_KOREAN_QUERY = 'analyze_korean_query',
    ANALYZE_ENGLISH_QUERY = 'analyze_english_query',

    // 답변 생성 Generate Answer
    MRC_ANSWER = 'mrc_answer',
    PASSAGE_ANSWER = 'passage_answer',
    CONTEXT_ANSWER = 'context_answer',

    // 질의 생성 Generate Query
    CONTEXT_QUESTION = 'context_question',
    WELCOME_QUESTION = 'welcome_question',  // 월컴 후속질의

    // 큐레이팅 Curating
    CHANNEL_CURATING = 'channel_curating',
}


export const PromptTypeList = [
    // 요약 Summary
    {
        name: "월컴 메세지",
        type: PromptType.WELCOME
    },

    // 질의 분석 Analyze
    {
        name: "한글 질의분석",
        type: PromptType.ANALYZE_KOREAN_QUERY
    },
    {
        name: "영어 질의분석",
        type: PromptType.ANALYZE_ENGLISH_QUERY
    },

    // 답변 생성 Generate Answer
    {
        name: "Passage 답변 생성",
        type: PromptType.PASSAGE_ANSWER
    },
    {
        name: "MRC 답변 생성",
        type: PromptType.MRC_ANSWER
    },
    {
        name: "대화이력 답변 생성",
        type: PromptType.CONTEXT_ANSWER
    },

    // 질의 생성 Generate Query
    {
        name: "웰컴 추천질의",
        type: PromptType.WELCOME_QUESTION
    },

    {
        name: "후속 질의",
        type: PromptType.CONTEXT_QUESTION
    },

    // 큐레이팅 Curating
    {
        name: "채널 사용량 큐레이팅",
        type: PromptType.CHANNEL_CURATING
    },

]

export interface PromptContentItem {
    role: string
    content: string
}


export enum LLMType {
    GPT = 'GPT',
    LLM42 = 'LLM42',
}

export const LLMTypeList = [
    {
        type: LLMType.GPT,
        model: ["gpt-3.5-turbo", "gpt-3.5-turbo-16k", "gpt-4", "gpt-4-32k", "azure-chatgpt", "HCX-002"]
    },
    {
        type: LLMType.LLM42,
        model: ["LLM42", "LLM42_qa", "LLM42_qc"]
    }
]

export interface PromptBase {
    key: string
    description?: string
    type: PromptType
    dependency: boolean;
    llmType: LLMType
    content: PromptContentItem[]
}

export interface Prompt extends PromptBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface PromptHistoryBase {
    prompt: Prompt
    version: number
}

export interface PromptHistory extends PromptHistoryBase {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export type PromptCreateRequest = Omit<PromptBase, "dependency">
export type PromptUpdateRequest = Omit<PromptBase, "dependency">

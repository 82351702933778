import React, {useRef, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import toast from "react-hot-toast";
import subscriptionTransactionRepository from "../../respsitory/SubscriptionTransactionRepository";
import {SubscriptionTransaction} from "../../model/transaction/subscriptionTransaction";
import {Billing, CurrencyUnit} from "../../model/transaction/transaction";
import styled from "styled-components";
import useModal from "../../hook/useModal";
import SubscriptionTransactionDetailModal, {
    SUBSCRIPTION_TRANSACTION_DETAIL_MODAL_ID
} from "../../component/payments/SubscriptionTransactionDetailModal";
import {useIntl} from "react-intl";
import useSubscriptionTransactions from "../../query/transaction/useSubscriptionTransactions";


const PaymentsHistoryView: React.FC = () => {
    const [startDate, setStartDate] = useState<Date|null>(null);
    const [endDate, setEndDate] = useState<Date|null>(null);

    const [channelName, setChannelName] = useState<string>();
    const [planName, setPlanName] = useState<string>();
    const [convertedStartDate, setConvertedStartDate] = useState<string>();
    const [convertedEndDate, setConvertedEndDate] = useState<string>();

    const channelNameRef = useRef<HTMLInputElement>(null);
    const planNameRef = useRef<HTMLInputElement>(null);

    const {
        data: pagedSubscriptionTransactions,
        hasNextPage,
        fetchNextPage,
        isLoading,
    } = useSubscriptionTransactions(
        channelName,
        planName,
        convertedStartDate,
        convertedEndDate
    )

    const triggerSearchWhenEnterKeyDown = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && !e.nativeEvent.isComposing) {
            await onClickSearch();
        }
    }

    const onClickSearch = async () => {
        setChannelName(channelNameRef.current?.value);
        // setPlanName(planNameRef.current?.value);
        setConvertedStartDate(startDate?.toISOString());
        setConvertedEndDate(endDate?.toISOString());
    }

    const onClickClear = () => {
        if (channelNameRef.current) {
            channelNameRef.current.value = "";
        }

        if (planNameRef.current) {
            planNameRef.current.value = "";
        }

        setStartDate(null);
        setEndDate(null);

        setChannelName(undefined);
        setPlanName(undefined);
        setConvertedStartDate(undefined);
        setConvertedEndDate(undefined);
    }

    const onClickExport = async () => {
        const channelName = channelNameRef.current?.value;
        // const planName = planNameRef.current?.value;

        try {
            const { data } = await subscriptionTransactionRepository.downloadCSV(
                channelName,
                planName,
                startDate?.toISOString(),
                endDate?.toISOString(),
            )

            const blob = new Blob([data], {type: "text.csv"});
            const url = window.URL.createObjectURL(blob);
            const filename = "사이트버니_결제내역.csv"

            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
        } catch (e) {
            toast.error("Failed to export payment histories.")
        }
    }

    const loadMoreTransactions = async () => {
        if (hasNextPage) {
            await fetchNextPage()
        }
    }

    return (
        <div className="w-full h-full bg-white font-noto pt-[28px] px-[36px] pb-[21px] flex flex-col">
            <div className="flex-grow flex flex-col space-y-[14px]">

                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-[7px]">
                        <DateRangeFilterStyle>
                            <ReactDatePicker className="border border-[#EAEAEA] rounded-[4px] w-[240px] h-[40px] text-[14px] text-[#505050] px-[16px] py-[11px] focus:outline-none"
                                             placeholderText={"클릭하여 기간을 선택하세요"}
                                             dateFormat={"yyyy-MM-dd"}
                                             selectsRange={true}
                                             startDate={startDate}
                                             endDate={endDate}
                                             showPopperArrow={false}
                                             onChange={(update) => {
                                                 setStartDate(update[0]);
                                                 setEndDate(update[1]);
                                             }} />
                        </DateRangeFilterStyle>

                        <input className="w-[365px] h-[40px] text-[14px] px-[16px] py-[11px] border border-[#EAEAEA] rounded-[4px] focus:outline-none"
                               ref={channelNameRef}
                               placeholder="채널명을 입력하세요."
                               onKeyDown={triggerSearchWhenEnterKeyDown}
                        />

                        {/*<input className="w-[365px] h-[45px] px-[16px] py-[11px] border border-[#EAEAEA] rounded-[4px] focus:outline-none"*/}
                        {/*       ref={planNameRef}*/}
                        {/*       placeholder="구독 플랜을 입력하세요."*/}
                        {/*       onKeyDown={triggerSearchWhenEnterKeyDown}*/}
                        {/*/>*/}

                        <button className="w-[45px] h-[40px] border border-[#EAEAEA] rounded-[4px] flex items-center justify-center"
                                onClick={onClickSearch}
                        >
                            <MagnifyingGlassIcon width={24} height={24}/>
                        </button>

                        <button className="w-[90px] h-[40px] border border-[#EAEAEA] rounded-[4px] px-[16px]"
                                onClick={onClickClear}
                        >
                            초기화
                        </button>
                    </div>

                    <button className="w-[120px] h-[40px] text-[14px] border border-[#EAEAEA] rounded-[4px] px-[16px] py-[11px]"
                            onClick={onClickExport}
                    >
                        CSV Export
                    </button>
                </div>

                <div className="flex-grow border border-[#EAEAEA] text-[14px] flex flex-col">
                    <div className="py-[10px] flex text-sm font-bold text-left bg-[#EAEAEA]">
                        {/*<div className="px-[12px] w-[50px] text-center">*/}
                        {/*    No*/}
                        {/*</div>*/}
                        <div className="px-[12px] w-[270px]">
                            채널명
                        </div>
                        <div className="px-[12px] w-[150px]">
                            요금제
                        </div>
                        <div className="px-[12px] w-[150px]">
                            결제 금액
                        </div>
                        <div className="px-[12px] w-[250px]">
                            결제일자
                        </div>
                        {/*<div className="px-[12px] w-[200px]">*/}
                        {/*    구독 시작일자*/}
                        {/*</div>*/}
                        <div className="px-[12px] w-[220px]">
                            카드 번호
                        </div>
                        {/*<div className="px-[12px] w-[150px]">*/}
                        {/*    채널 관리자명*/}
                        {/*</div>*/}
                        <div className="px-[12px] w-[150px]">
                            청구서 수령인 이메일
                        </div>
                    </div>
                    <div className="text-sm overflow-x-hidden overflow-y-auto h-0 flex-grow -mr-[10px]">
                        {pagedSubscriptionTransactions?.pages.map(page => (
                            page.content.map((item, index) => (
                                <SubscriptionTransactionRow key={item.transaction._id}
                                                            index={index}
                                                            subscriptionTransaction={item}
                                />
                            ))
                        ))}
                    </div>

                </div>
                {hasNextPage &&
                    <button className="p-2 btn btn-secondary w-full"
                            onClick={loadMoreTransactions}
                            disabled={isLoading}
                    >
                        더 불러오기
                    </button>
                }
            </div>

            <SubscriptionTransactionDetailModal />
        </div>
    )
};

type SubscriptionTransactionRowProps = {
    index: number;
    subscriptionTransaction: SubscriptionTransaction;
}

const SubscriptionTransactionRow: React.FC<SubscriptionTransactionRowProps> = (props) => {
    const { channel, transaction, channelSubscriptionHistory } = props.subscriptionTransaction;

    const modal = useModal();
    const intl = useIntl();

    const convertBillingAmount = (billing: Billing) => {
        switch (billing.currencyUnit) {
            case CurrencyUnit.KRW:
                return `${intl.formatNumber(billing.amount)}원`;
            case CurrencyUnit.USD:
                return `$${billing.amount}`;
        }
    }

    // const isRefund = useMemo(() => {
    //     return transaction.billing.amount < 0;
    // }, [transaction])

    const onClick = () => {
        modal.open(SUBSCRIPTION_TRANSACTION_DETAIL_MODAL_ID, props.subscriptionTransaction)
    }

    return (
        <div key={`payment-history-${transaction._id}`}
             className="h-[45px] flex items-center bg-white cursor-pointer hover:bg-gray-100 border-b text-[14px]"
             onClick={onClick}
        >
            {/*<div className="px-[12px] w-[50px] text-center">*/}
            {/*    {props.index + 1}*/}
            {/*</div>*/}
            <div className="px-[12px] w-[270px]">
                {channel?.name ?? "-"}
            </div>
            <div className="px-[12px] w-[150px]">
                {channelSubscriptionHistory?.subscription.plan.name ?? "-"}
            </div>
            <div className="px-[12px] w-[150px] flex items-center">
                {convertBillingAmount(transaction.billing)}
                {/*{isRefund &&*/}
                {/*    <div className="ml-[6px] rounded-[5px] bg-[#ff6666] text-[12px] text-white px-[8px] py-[3px]">*/}
                {/*        환불*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            <div className="px-[12px] w-[250px] flex items-center">
                {moment.utc(transaction.createdAt).local().format("YYYY-MM-DD HH:mm")}
                {/*{isRefund &&*/}
                {/*    <div className="ml-[6px] rounded-[5px] bg-[#ff6666] text-[12px] text-white px-[8px] py-[3px]">*/}
                {/*        환불일자*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            {/*<div className="px-[12px] w-[200px]">*/}
            {/*    {channelSubscriptionHistory ? moment.utc(channelSubscriptionHistory.subscription.startDate).local().format("YYYY-MM-DD HH:mm") : "-"}*/}
            {/*</div>*/}
            <div className="px-[12px] w-[220px] flex items-center">
                ****-****-****-{transaction.card.cardNumber.slice(-4)}
            </div>
            {/*<div className="px-[12px] w-[150px]">*/}
            {/*    {user?.fullName ?? "-"}*/}
            {/*</div>*/}
            <div className="px-[12px] w-[150px]">
                {transaction.card.email ?? "-"}
            </div>
        </div>
    )
}

const DateRangeFilterStyle = styled.div`
  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
  }
`

export default PaymentsHistoryView;

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"

import {toast} from "sonner"
import useJobs from "@/query/workflowJob/useJobs";
import React, {useMemo} from "react";
import VisibilitySensor from "@/components/ui/sensor";
import {useNavigate} from "react-router-dom";
import {formatIsoReadable} from "@/util/dateUtils";
import {Job} from "@/model/Job";
import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import useInterruptJob from "@/query/workflowJob/useInterruptJob";
import {Progress} from "@/components/ui/progress";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Textarea} from "@/components/ui/textarea";


const stringfyResult = (data: any) => {
    if (typeof data === 'string') {
        return data; // 문자열은 그대로 반환
    } else if (typeof data === 'number') {
        return data.toString(); // 숫자는 문자열로 변환
    } else if (typeof data === 'boolean') {
        return data ? 'true' : 'false'; // 불리언은 'true' 또는 'false'로 변환
    } else if (Array.isArray(data)) {
        return data.join(', '); // 배열은 쉼표로 구분된 문자열로 변환
    } else if (typeof data === 'object' && data !== null) {
        return JSON.stringify(data, null, 2); // 객체는 JSON 문자열로 변환
    } else if (data === null) {
        return 'null'; // null은 'null' 문자열로 변환
    } else if (data === undefined) {
        return 'undefined'; // undefined는 'undefined' 문자열로 변환
    } else {
        return 'undecodable data'; // 그 외 타입은 빈 문자열 반환
    }
}
export const JobList = () => {
    // const location = useLocation()
    const navigate = useNavigate()
    // const groupId = useMemo(() => {
    //     const grouplIdParam = queryString.parse(location.search).groupId
    //     return typeof grouplIdParam === 'string' ? grouplIdParam : undefined
    // }, [location.search])
    //
    // const [channelNameQuery, setChannelNameQuery] = useState<string | undefined>(undefined)
    // const {data: channelsResponse} = useChannels(1, 20, SearchTypeEnum.CHANNELNAME, channelNameQuery, false);
    // const {data: channelResponse} = useChannel(channelId)
    //
    // const onChangeChannelNameQuery = (channelName?: string ) => {
    //     setChannelNameQuery(channelName)
    // };
    //
    // const setChannelId = (value?: string) => {
    //     if (!value || channelId === value) {
    //         navigate(`/jobs`)
    //     } else {
    //         navigate(`/jobs?channelId=${value}`)
    //     }
    // }
    const {
        data,
        hasNextPage,
        fetchNextPage,
        isLoading,
    } = useJobs()

    const onScrollAtEnd = async () => {
        if (hasNextPage && !isLoading) {
            await fetchNextPage();
        }
    }

    const jobs = useMemo(() => {
        return data?.pages.map((page) => page.content).flat()
    }, [data])

    return (
        <div>
            {/*<div className='flex mb-3'>*/}
            {/*    <Combobox onChangeQuery={onChangeChannelNameQuery}*/}
            {/*              items={channelsResponse ? channelsResponse?.channels.map((channel) => ({value: channel._id, label: channel.name})) : []}*/}
            {/*              placeholder='Select Channel'*/}
            {/*              onChangeValue={setChannelId}*/}
            {/*              value={channelResponse ? {value: channelResponse.channel._id, label: channelResponse.channel.name} : undefined}*/}
            {/*    />*/}
            {/*</div>*/}

            <Table>
                {/*<TableCaption>A list of your recent invoices.</TableCaption>*/}
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px] text-center">Type</TableHead>
                        {/*<TableHead >Channel</TableHead>*/}
                        <TableHead className='text-center'>Status</TableHead>
                        <TableHead className='text-left'>Created At</TableHead>
                        <TableHead className='text-left'></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {jobs?.map((job) => (
                        <TableRow key={job._id}>
                            <>
                                <TableCell className="font-medium text-center">{job.type}</TableCell>
                                {/*<TableCell className="font-medium">{job.channelName}</TableCell>*/}
                                <TableCell className="font-medium text-center">
                                    {jobStatus(job)}
                                </TableCell>
                                <TableCell className="font-medium text-left">
                                    {formatIsoReadable(job.createdAt)}
                                </TableCell>
                                <TableCell className="font-medium text-left">
                                    <Sheet>
                                        <SheetTrigger asChild>
                                            <Button variant="ghost">Detail</Button>
                                        </SheetTrigger>
                                        <JobDialogContent job={job}/>
                                    </Sheet>
                                    <Button variant="ghost" onClick={() => navigate(`/results?groupId=${job._id}`)}>Results</Button>
                                </TableCell>
                            </>
                        </TableRow>

                    ))}
                    {jobs?.length === 0 && <TableRow className='border-0'><TableCell colSpan={4}>No result.</TableCell></TableRow>}
                    {!isLoading &&
                        <tr>
                            <td>
                                <VisibilitySensor onEnter={onScrollAtEnd}/>
                            </td>
                        </tr>
                    }

                </TableBody>

                {/*<TableFooter>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell colSpan={3}>Total</TableCell>*/}
                {/*        <TableCell className="text-right">$2,500.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableFooter>*/}
            </Table>

        </div>
    )

}

const jobStatus = (job: Job) => {
    if (job.status === 'running') {
        return <Progress value={job.progressRate}/>
    } else if (job.status === 'done') {
        return <Badge variant="outline">Done</Badge>
    } else if (job.status === 'failed') {
        return <Badge variant="destructive">Failed</Badge>
    }
}

const JobDialogContent: React.FC<{ job: Job }> = ({job}) => {
    const {mutateAsync: _interrupt} = useInterruptJob(job._id)

    const interrupt = async () => {
        toast.promise(_interrupt, {
            error: (e) => {
                return e.response.data
            },
        })
    }
    return (
        <SheetContent
            className="w-[500px] sm:w-[500px] sm:max-w-[500px]"
        >
            <SheetHeader>
                <SheetTitle className='flex items-center'>
                    <span className='mr-2'>{job.name ?? `${job.type}-${job._id}`}</span> {jobStatus(job)}
                </SheetTitle>
                <SheetDescription>

                </SheetDescription>
            </SheetHeader>

            <div className="grid gap-4 py-4">
                <div className="grid w-full gap-1.5">
                    <Label htmlFor="message">Type</Label>
                    <Input value={job.type} readOnly={true}/>
                </div>

                <div className="grid w-full gap-1.5">
                    <Label htmlFor="message">Result</Label>
                    <Textarea value={stringfyResult(job.result)} readOnly={true} rows={20}
                              autoFocus={false}/>
                </div>
            </div>


            <SheetFooter>
                {job.status === 'running' &&
                    <Button type="submit" variant='destructive' onClick={interrupt}>
                        Interrupt
                    </Button>
                }
                <Button type="submit" variant='destructive' onClick={interrupt}>
                    Interrupt
                </Button>
                {/*<SheetClose asChild>*/}
                {/*    <Button type="submit">Save changes</Button>*/}
                {/*</SheetClose>*/}
            </SheetFooter>
        </SheetContent>
    )
}


export default JobList;
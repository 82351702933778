import React, {useMemo} from "react";
import {AxiosError} from "axios";
import ErrorView from "./ErrorView";
import {useIntl} from "react-intl";
import {SiteBunnyAdminError} from "@/error";
import {Navigate} from "react-router-dom";

const ErrorFallback: React.FC<{error: Error, resetErrorBoundary: (...args: Array<unknown>) => void}> = ({error, resetErrorBoundary}) => {
    const intl = useIntl();

    const axiosError = useMemo(() => {
        if ('isAxiosError' in error) {
            const axiosError = error as AxiosError
            if (axiosError.isAxiosError) {
                return axiosError;
            }
        }
        return null;
    }, [error]);
    const siteBunnyAdminError = useMemo(() => {
        if ('isSiteBunnyAdminError' in error) {
            const _siteBunnyAdminError = error as SiteBunnyAdminError
            if (_siteBunnyAdminError.isSiteBunnyAdminError) {
                return _siteBunnyAdminError;
            }
        }

        return null;
    }, [error]);

    const code = useMemo(() => {
        if (axiosError) {
            return axiosError.response?.status.toString();
        }
        else if (siteBunnyAdminError) {
            return siteBunnyAdminError.code;
        }
        return null;
    }, [axiosError, siteBunnyAdminError]);
    if (code === "403") {
        return (
            <ErrorView code={code}
                       title={intl.formatMessage({id: "e0001"})}
                       description={intl.formatMessage({id: "e0002"})}
                       resetErrorBoundary={resetErrorBoundary}
            />
        )
    } else if (code === "404") {
        return (
            <ErrorView code={code}
                       title={intl.formatMessage({id: "e0003"})}
                       description={intl.formatMessage({id: "e0004"})}
                       resetErrorBoundary={resetErrorBoundary}
            />
        )
    } else if (code === "401") {
        return <Navigate to="/login"/>
    } else {
        return (
            <ErrorView code="500"
                       title={intl.formatMessage({id: "e0005"})}
                       description={intl.formatMessage({id: "e0006"})}
                       resetErrorBoundary={resetErrorBoundary}
            />
        )
    }
}

export default ErrorFallback;
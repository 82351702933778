import React, {useCallback, useMemo, useState} from "react";
import useLLMSpecifications from "../../query/llm/useLLMSpecifications";
import {LLMSpecification, LLMSpecificationBase} from "../../model/llmSpecification";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";
// import LLMSpecificationGuide from "../../component/llm/LLMSpecificationGuide";
import LLMSpecificationSettingForm from "../../component/llm/LLMSpecificationSettingForm";


const LLMMain: React.FC = () => {
    const [searchKey, setSearchKey] = useState<string>();

    const { data: specifications } = useLLMSpecifications(searchKey);

    const [showForm, setShowForm] = useState(false);
    const [selectedSpecificationKey, setSelectedSpecificationKey] = useState<string>();
    const [copySpecification, setCopySpecification] = useState<LLMSpecificationBase>();

    const selectedSpecification = useMemo(() => {
        return specifications?.find(specification => specification.key === selectedSpecificationKey);
    }, [specifications, selectedSpecificationKey]);

    const hideForm = useCallback(() => {
        setSelectedSpecificationKey(undefined);
        setShowForm(false);
    }, [setSelectedSpecificationKey, setShowForm]);

    const onChangeSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        hideForm();
    }, 300);

    const onClickSpecification = (specification: LLMSpecification) => {
        setSelectedSpecificationKey(specification.key)
        setShowForm(true);
    }

    const onClickAddSpecification = () => {
        setCopySpecification(undefined);
        setSelectedSpecificationKey(undefined);
        setShowForm(true);
    }

    const onClickCopySpecification = (specification: LLMSpecificationBase) => {
        setCopySpecification(specification);
        setSelectedSpecificationKey(undefined);
        setShowForm(true);
    }



    const onClickCancelSpecification = () => {
        hideForm();
    }

    const onClickDeleteSpecification = () => {
        hideForm();
    }

    const onClickSubmitSpecification = (specificationKey: string) => {
        setSelectedSpecificationKey(specificationKey);
    }

    return (
        <div className="flex flex-col h-full">
            {/* Title */}

            {/* Content */}
            <div className="m-5 flex space-x-5">
                <div className="w-[30%] flex flex-col space-y-2">

                    <div className="flex items-center justify-between">
                        <p className="text-xl pl-2">LLM 목록</p>

                        <button className="btn btn-info btn-sm" onClick={onClickAddSpecification}>
                            추가하기
                        </button>
                    </div>


                    {/* Search bar */}
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon className="w-5 h-5"/>
                        </div>
                        <input type="search"
                               id="search"
                               className="form-input pl-10"
                               placeholder="Search by key"
                               onChange={onChangeSearch}
                        />
                    </div>

                    {/* List */}
                    <div className="flex-grow space-y-2 overflow-y-auto max-h-[800px]">
                        {specifications?.map((specification) => {
                            const description = specification.description === "" ? "설명 없음" : (specification?.description ?? "설명 없음")
                            return (
                                <div key={specification._id}
                                     className={classNames(
                                         "border border-gray-600 rounded-lg px-5 py-3 cursor-pointer transition-colors duration-200 hover:bg-gray-200",
                                         selectedSpecificationKey === specification.key && "bg-gray-300"
                                     )}
                                     onClick={() => onClickSpecification(specification)}>

                                    <div className="flex items-center justify-between space-x-2">
                                        <p className="text-lg truncate">{specification.key}</p>
                                        {specification.dependency &&
                                            <div className="px-3 py-1 rounded-md bg-blue-400 text-white text-sm whitespace-nowrap">
                                                사용중
                                            </div>
                                        }
                                    </div>
                                    <p className="text-sm">{description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex-grow space-y-2">
                    {showForm &&
                        <LLMSpecificationSettingForm key={`specification-setting-form-${selectedSpecification?._id}`}
                                                     specification={selectedSpecification}
                                                     copySpecification={copySpecification}
                                                     onCancel={onClickCancelSpecification}
                                                     onDelete={onClickDeleteSpecification}
                                                     onSubmit={onClickSubmitSpecification}
                                                     onClickCopySpecification={onClickCopySpecification}
                        />
                    }

                    {/*<LLMSpecificationGuide />*/}
                </div>
            </div>
        </div>
    );
};

export default LLMMain;

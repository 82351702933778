import {Button} from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {Input} from "@/components/ui/input"
import {Label} from "@/components/ui/label"
import React, {useMemo, useRef, useState} from "react";
import jobRepository from "../respsitory/workflowJobRepository";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {Channel} from "@/model/channel/channel";
import {Textarea} from "@/components/ui/textarea";

export const ChatBulkTestDialog: React.FC<{ channel: Channel }> = ({channel}) => {
    const navigate = useNavigate();
    const fileRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [mutating, setMutating] = useState<boolean>(false);
    const [version, setVersion] = useState<string>("v2");

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        setFile(e.target.files[0]);
    };

    const defaultParams = useMemo(() => ({
        "channelId": channel._id,
        "faqIndexName": `sitebunny-source-embed-${channel.indexId}`,
        "ragIndexName": `sitebunny-source-embed-${channel.indexId}`,
        "version": version
    }), [channel, version])
    const submit = async () => {
        if (!file || mutating) return;

        setMutating(true);
        try {
            await jobRepository.bulkTestWorkflow(
                channel.setting.channelCommonSettingId,
                file,
                defaultParams)
            navigate(`/jobs`)
        } catch (e: any) {
            console.log(e)
            toast.error("실패했습니다.")
        }
        setMutating(false);
    };


    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">Chat Bulk Test</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Chat Bulk Test</DialogTitle>
                    <DialogDescription>
                        xlsx 파일을 활용하여 질의 처리를 테스트합니다.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="picture">Test Set</Label>
                        <Input id="picture" type="file" ref={fileRef} onChange={handleFileChange}/>
                    </div>

                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="version">Version</Label>
                        <Input
                            id="version"
                            type="text"
                            value={version}
                            onChange={(e) => setVersion(e.target.value)}
                        />
                    </div>

                    <div className="grid w-full max-w-sm items-center gap-1.5">
                        <Label htmlFor="message">Default Params</Label>
                        <Textarea value={JSON.stringify(defaultParams, null, 2)}
                                  rows={5}
                                  autoFocus={false}/>
                    </div>


                </div>
                <DialogFooter>
                    <Button type="submit" onClick={submit}>Save</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import jobRepository from "../../respsitory/workflowJobRepository";


const useInterruptJob = (jobId: string) => {
    return useMutation<undefined, AxiosError>({
        mutationKey: ["jobs", jobId, 'command'],
        mutationFn: async () => {
            try {
                const {data} = await jobRepository.interrupt(jobId);
                return data.result;
            } catch (e) {
                throw e;
            }
        }
    })
};

export default useInterruptJob;
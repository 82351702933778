import jobRepository from "../../respsitory/workflowJobRepository";
import {useInfiniteQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Page} from "../index";
import {Job} from "@/model/Job";


const getData = async (page: number, size: number) => {
    try {
        const {data} = await jobRepository.paginate(page, size)
        return {
            content: data.result.content,
            pageable: {
                skip: page,
                limit: size,
                isLast: data.result.isLast
            }
        }
    } catch (e) {
        throw e;
    }
}

const LIMIT = 20
const useJobs = () => {
    return useInfiniteQuery<Page<Job>, AxiosError>({
        queryKey: ["workflow", "jobs"],
        queryFn: async ({ pageParam = { skip: 1, limit: LIMIT }}) => getData(
            pageParam.skip, pageParam.limit
        ),
        refetchOnMount: true,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined
            }

            return {
                skip: pageable.skip + 1,
                limit: pageable.limit,
            }
        }
    })
}

export default useJobs;

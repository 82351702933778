import {cn} from "@/lib/utils"
import {OperatorOutlet} from "@/model/Log";
import classNames from "classnames";
import {ScrollArea} from "@/components/ui/scroll-area";
import moment from "moment";
import {Separator} from "@/components/ui/separator";

interface MailListProps {
    items: OperatorOutlet[],
    setOperatorName: (operatorName: string) => void,
    selectedOperatorName?: string
    params?: {[key: string]: any}
}

export function OperatorOutletList({items, setOperatorName, selectedOperatorName, params}: MailListProps) {

    return (
        <ScrollArea className="h-full">
            <div className="flex flex-col gap-2 p-4 pt-0">
                {params &&
                    <div
                        className={cn(
                            "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                        )}
                    >
                        <div className="font-semibold">params</div>
                        <Separator/>
                        <div className="flex-1 whitespace-pre-wrap  text-muted-foreground">
                            {JSON.stringify(params, null, 2)}
                        </div>
                    </div>
                }

                {items.map((item) => (
                    <OutletCard outlet={item} setOperatorName={setOperatorName}
                                selectedOperatorName={selectedOperatorName}/>
                ))}
            </div>
        </ScrollArea>
    )
}

const OutletCard: React.FC<{
    outlet: OperatorOutlet,
    setOperatorName: (operatorName: string) => void,
    selectedOperatorName?: string
}> = ({outlet, setOperatorName, selectedOperatorName}) => {
    return (
        <button
            key={outlet.operatorName}
            className={cn(
                "flex flex-col outlets-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                outlet.operatorName === selectedOperatorName && "bg-muted"
            )}
            onClick={() => setOperatorName(outlet.operatorName)}
        >
            <div className="flex w-full flex-col gap-1">
                <div className="flex items-center">
                    <div className="flex items-center gap-2">
                        <div className="font-semibold">{outlet.operatorName}</div>
                        <span className={classNames(
                            "flex h-2 w-2 rounded-full",
                            outlet.type === 'failed' ? "bg-red-600" : "bg-blue-600"
                        )}
                        />
                    </div>
                    <div
                        className={cn(
                            "ml-auto text-xs",
                            outlet.operatorName === selectedOperatorName
                                ? "text-foreground"
                                : "text-muted-foreground"
                        )}
                    >
                        {moment.utc(outlet.endedAt).diff(moment.utc(outlet.startedAt), 'milliseconds').toLocaleString("ko-KR")}ms
                    </div>
                </div>
                <div className="text-xs font-medium">{outlet.type}</div>
            </div>
            <Separator/>
            <div className="flex-1 whitespace-pre-wrap text-muted-foreground">
                {JSON.stringify(outlet.outlet, null, 2)}
            </div>
        </button>
    )
}
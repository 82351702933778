import React, {useMemo} from 'react';
import {IntlProvider} from "react-intl";
import {createBrowserRouter, Navigate, Outlet, RouteObject, useRoutes} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import LoginView from "./view/auth/LoginView";
import ErrorFallback from "./view/ErrorFallbackView";
import {Toaster} from "react-hot-toast";
import { Toaster as ShadCDNToast } from "@/components/ui/sonner"
import {DictType} from "./model";
import ko from "./i18n/ko";
import BaseDialog from "./hook/useDialog";
import routes from './route'


const AuthRequiredRoutes = () => {
    return useRoutes(routes)
}

export const App = () => {
    const messages: DictType<Record<string, string>> = useMemo(() => {
        return {"ko": ko};
    }, []);
    return (
        <IntlProvider locale={'ko'} messages={messages['ko']}>
            <div className='w-full h-full font-sans'>
                <Toaster position="top-center"
                         containerStyle={{top: "15px"}}
                         toastOptions={{
                             style: {
                                 maxWidth: "400px"
                             }
                         }}
                />
                <ShadCDNToast richColors={true}/>
                <BaseDialog/>
                <Outlet/>
            </div>
        </IntlProvider>
    );
}

const AppRouterInfo: RouteObject[] = [
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <Navigate replace to="dashboard"/>
            },
            {
                path: "/login",
                element: <LoginView/>,
            },
            {
                path: "/*",
                element: <AuthRequiredRoutes/>
            }
        ],
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback}/>
    },
];

export const appRouterObject = createBrowserRouter(AppRouterInfo);

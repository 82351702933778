import React, {useMemo} from "react";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import WorkflowLogList from "@/view/workflowLog/workflowLogList";


export const WorkflowLogMain = () => {
    const location = useLocation()
    const groupId = useMemo(() => {
        const groupIdParam = queryString.parse(location.search).groupId
        return typeof groupIdParam === 'string' ? groupIdParam : undefined
    }, [location.search])


    return <WorkflowLogList groupId={groupId}/>

}


export default WorkflowLogList;
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";

export interface VisibilitySensorRef {
    visible: () => boolean
    visibleScroll: () => boolean
}

interface VisibilitySensorProps {
    onEnter?: () => void
    onExit?: () => void
}
const VisibilitySensor = forwardRef<VisibilitySensorRef, VisibilitySensorProps>(({onEnter, onExit}, ref) => {
    const _ref = useRef<HTMLDivElement>(null)
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useImperativeHandle(ref, () => {
        return {
            visible: () => {
                return isIntersecting
            },
            visibleScroll: () => {
                // 스크롤이 안보일때 보일때까지 페이지를 가져오기위한 상태값
                if (_ref.current) {
                    const rect = _ref.current.getBoundingClientRect();
                    return !(rect.top < window.innerHeight && rect.bottom > 0)
                } else {
                    return true
                }
            }
        }
    }, [isIntersecting, _ref]);

    useEffect(() => {
        if (isIntersecting) {
            onEnter?.()
        } else {
            onExit?.()
        }
    }, [onEnter, onExit, isIntersecting])

    useEffect(() => {
        if (_ref.current) {
            observer.observe(_ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [_ref, observer]);

    return (
        <div ref={_ref} className='invisible h-[5px]'/>
    );
})

export default VisibilitySensor
import axios from "axios";
import {JOB_API_HOST} from "@/core/variables";

class WorkflowJobRepository {
    async paginate(page: number, size: number) {
        return axios.get(`${JOB_API_HOST}`, {
            params: {page, size}
        })
    };
    //
    // async read(id: string) {
    //     return axios.get(`${API_HOST}/v1/channel-group/${id}`)
    // };

    async bulkTestWorkflow(workflowId: string, file: File, commonParams: {[key: string]: string}) {
        console.log(workflowId, commonParams)
        let form = new FormData();
        form.append("file", file);
        form.append('workflowId', workflowId)
        form.append('defaultParams', JSON.stringify(commonParams))
        // Object.entries(commonParams).forEach(([key, value]) => {
        //     form.append(key, value)
        // })
        console.log(form)

        return axios.post(
            `${JOB_API_HOST}/bulk-test`,
            form,
            {
                headers: {"Content-Type": "multipart/form-data"},
                timeout: 30000,

            }
        )
    };

    async interrupt(jobId: string) {
        return axios.post(
            `${JOB_API_HOST}/${jobId}/interrupt`,
        )
    };


}
const jobRepository = new WorkflowJobRepository();
export default jobRepository

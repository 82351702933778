import ErrorBoundaryLayout from "./component/error/ErrorBoundaryLayout";
import NewDashboardView from "./view/dashboard/NewDashboardView";
import ChannelTab from "./view/channel/ChannelTab";
import ChannelDetailTab from "./view/channel/setting/ChannelDetailTab";
import QueryLogDetail from "./component/queryLog/QueryLogDetail";
import PaymentsHistoryView from "./view/payments/PaymentsHistoryView";
import UserView from "./view/user/UserView";
import UserDetail from "./view/user/UserDetail";
import LLMMain from "./view/llm/LLMMain";
import PromptSettingView from "./view/prompt/PromptSettingView";
import SearchSettingView from "./view/searchSpecification/SearchSettingView";
import SettingTab from "./view/setting/SettingTab";
import React from "react";
import {RouteObject} from "react-router-dom";
import JobList from "@/view/job/JobList";
import {WorkflowLogMain} from "@/view/workflowLog/workflowLogMain";

export type Route  = RouteObject & {
    title?: string
}

const routes = [
    {
        path: "",
        element: <ErrorBoundaryLayout/>,
        children: [
            {title: "Dashboard", path: 'dashboard', element: <NewDashboardView/>},
            {
                title: "Channel",
                path: "channels",
                children: [
                    {
                        index: true,
                        element: <ChannelTab/>
                    },
                    {
                        path: ":channelId",
                        element: <ChannelDetailTab/>
                    },
                    {
                        path: ":channelId/query-logs/:queryLogId",
                        element: <QueryLogDetail/>
                    }
                ]
            },
            {
                title: "Payment",
                path: "payments",
                element: <PaymentsHistoryView/>
            },
            {
                title: "User",
                path: "user",
                children: [
                    {
                        index: true,
                        element: <UserView/>
                    },
                    {
                        path: ":userId",
                        element: <UserDetail/>
                    }
                ]
            },
            {
                title: "LLM",
                path: "llms",
                element: <LLMMain/>,
            },
            {
                title: "Prompt",
                path: "prompt",
                element: <PromptSettingView/>,
            },
            {
                title: "Document Search",
                path: "search",
                element: <SearchSettingView/>,
            },
            {
                title: "Setting",
                path: "setting",
                element: <SettingTab/>,
            },
            {
                title: "Workflow",
                path: "workflow",
                element: <div> workflow </div>,
            },
            {
                title: "Workflow Job",
                path: "jobs",
                element: <JobList/>,
            },
            {
                title: "Workflow Result",
                path: "results",
                element: <WorkflowLogMain/>,
            },
        ],
    },
]
export default routes
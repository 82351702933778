import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import Authenticator from "@/component/Authenticator";

const ErrorBoundaryLayout: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Authenticator/>
        </ErrorBoundary>
    );
};

export default ErrorBoundaryLayout;

export const APP_TITLE = process.env.REACT_APP_TITLE;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const AXIOS_DEFAULTS_TIMEOUT = process.env.REACT_APP_AXIOS_DEFAULTS_TIMEOUT;
export const INTEGRATION_CM_URL = process.env.REACT_APP_CM_URL;
export const INTEGRATION_QA42_URL = process.env.REACT_APP_QA42_URL;
export const INTEGRATION_WEBCHAT_URL = process.env.REACT_APP_WEBCHAT_URL;
export const INTEGRATION_ONBOARDING_URL = process.env.REACT_APP_ONBOARDING_URL;

export const RESTORATION_FEATURE_DEVELOPED_AT = "2023-12-11T15:00:00.000000Z";

export const JOB_API_HOST = process.env.REACT_APP_JOB_API_HOST;
export const WOKRFLOW_API_HOST = process.env.REACT_APP_WORKFLOW_API_HOST;

import {Channel, ChannelPage, Language} from "../../model/channel/channel";
import useChannels from "../../query/channel/useChannels";
import React, {useCallback, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {isSearchType, SearchType, SearchTypeEnum} from "../../respsitory/ChannelRepository";
import {SiteBunnyAdminError} from "../../error";

import CategorySearchBar from "../../component/CategorySearchBar";
import PageBar from "../../component/PageBar";
import ChannelRow from "./ChannelRow";

const ItemCount = 10;
const ChannelMainView: React.FC = () => {
    const intl = useIntl();

    const [searchParams, setSearchParams] = useSearchParams();
    const [showDeletedChannel, setShowDeletedChannel] = useState<boolean>(false);
    const [showLanguageChannel, setShowLanguageChannel] = useState<Language>();
    const [showStarChannel, setShowStarChannel] = useState<boolean>();

    const searchCategory = useMemo(() => {
        return [
            {
                category: SearchTypeEnum.CHANNELNAME,
                expression: intl.formatMessage({id: 'i1001'})
            },
            {
                category: SearchTypeEnum.USERNAME,
                expression: intl.formatMessage({id: 'i10031'})
            },
            {
                category: SearchTypeEnum.GROUPNAME,
                expression: intl.formatMessage({id: 'i10032'})
            }
        ]
    }, [intl])

    const defaultPage: number = useMemo(() => {
        return parseInt(searchParams.get("page") ?? "1")
    }, [searchParams]);

    const defaultCategory: SearchType | undefined = useMemo(() => {
        return isSearchType(searchParams.get("category")) ? searchParams.get("category") as SearchType : undefined
    }, [searchParams]);

    const defaultKeyword = useMemo(() => {
        return searchParams.get("keyword") ?? undefined
    }, [searchParams]);

    const onSubmitKeyword = useCallback((category: string, keyword: string) => {
        setSearchParams({category, keyword})
    }, [setSearchParams])

    const {data: pagination} = useChannels(
        defaultPage,
        ItemCount,
        defaultCategory,
        defaultKeyword,
        showDeletedChannel,
        showLanguageChannel,
        showStarChannel
    );
    const lastPage = useMemo(() => {
        if (!pagination) return 0;

        return Math.floor(pagination.count / ItemCount) + ((pagination.count % ItemCount) && 1);
    }, [pagination])

    if ((lastPage > 0 && defaultPage > lastPage) || defaultPage < 1) {
        throw new SiteBunnyAdminError("404", "Improper page number request");
    }

    const onClickPage = useCallback((page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams)

    }, [searchParams, setSearchParams]);

    return (
        <div className={"flex flex-col h-full text-[16px]"}>
            <div className="h-full px-8">
                <div className="flex justify-between">
                    <label className="text-2xl font-bold"></label>
                    <div>
                        <input type="checkbox"
                               onChange={() => {
                                   onClickPage(1);
                                   setShowStarChannel(!showStarChannel);
                               }}
                               className="mx-2"/>
                        <label className="text-lg">
                            중요 채널
                        </label>
                        <input type="checkbox"
                               onChange={() => {
                                   onClickPage(1);
                                   setShowLanguageChannel(showLanguageChannel ? undefined : Language.KOREAN);
                               }}
                               className="mx-2"/>
                        <label className="text-lg">
                            한글 채널
                        </label>
                        <input type="checkbox"
                               onChange={() => {
                                   onClickPage(1);
                                   setShowLanguageChannel(showLanguageChannel ? undefined : Language.ENGLISH);
                               }}
                               className="mx-2"/>
                        <label className="text-lg">
                            영어 채널
                        </label>
                        <input type="checkbox"
                               onChange={() => {
                                   onClickPage(1);
                                   setShowDeletedChannel(!showDeletedChannel);
                               }}
                               className="mx-2"/>
                        <label className="text-lg">
                            삭제된 채널
                        </label>
                    </div>
                </div>
                <div>
                    <label className="text-lg"> 전체 채널 수 : {pagination?.totalCount}</label>
                    <label className="text-lg"> 검색된 채널 수 : {pagination?.count}</label>
                </div>
                <CategorySearchBar className={"w-1/3 float-right"}
                                   categories={searchCategory}
                                   defaultCategory={defaultCategory}
                                   defaultKeyword={defaultKeyword}
                                   onSubmit={onSubmitKeyword}/>
                <ChannelTable
                    pagination={pagination}
                />
                <div className="flex justify-end">
                    <PageBar page={defaultPage} lastPage={lastPage} onClickPage={onClickPage} />
                </div>
            </div>
        </div>
    );
};


type ChannelTableProps = {
    pagination: ChannelPage | undefined,
}

const ChannelTable: React.FC<ChannelTableProps> = (props) => {
    const {pagination} = props;
    const intl = useIntl();
    const navigate = useNavigate();

    if (!pagination) {
        return (
            <div className={"w-full h-full flex justify-center items-center"}>
                {intl.formatMessage({id: "i10026"})}
            </div>
        )
    }
    const redirect = (channel: Channel) => {
        navigate(channel._id, {state: {channel: channel}});
    }
    return (
        <div className="py-2 inline-block min-w-full">
            {pagination.channels.length ?
                <div className={"flex flex-col justify-between"}>
                    <div className="">
                        <table className="w-full p-5 table-fixed break-keep sm:rounded-lg border-2">
                            <thead className="w-full text-sm font-bold text-left border-b bg-gray-200">
                            <tr className="text-[16px]">
                                <th scope="col" className="px-5 py-4 text-left w-[10%]">
                                    {intl.formatMessage({id: "i1001"})}
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[10%]">
                                    {intl.formatMessage({id: "i1002"})}
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[10%]">
                                    {intl.formatMessage({id: "i11101"})}
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                    요금제
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[15%]">
                                    메시지 사용량
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                    {intl.formatMessage({id: "i1003"})}
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[12%]">
                                    채널 생성일
                                </th>
                                <th scope="col" className="p-2.5 py-4 text-left w-[8%]">
                                    {intl.formatMessage({id: "i11028"})}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                {pagination.channels.map((channel, index) => {
                                        return (
                                            <ChannelRow
                                                key={channel._id}
                                                channel={channel}
                                                redirect={() => {redirect(channel)}}
                                            />)
                                    }
                                )
                                }

                                {(pagination.channels.length < ItemCount) && (
                                    [...Array(ItemCount - pagination?.channels.length)].map((v) => <tr className={"h-[67px]"}></tr>)
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                : <div
                    className={"w-full h-full flex justify-center items-center"}>{intl.formatMessage({id: "i10027"})}</div>
            }
        </div>
    );
};

export default ChannelMainView;

import React, {Dispatch, SetStateAction, useMemo} from "react";
import {PromptContentItem, Prompt } from "../../model/prompt/prompt";
import usePrompt from "../../query/prompt/usePrompt";
import {LLMSpecification} from "../../model/llmSpecification";
import { Agent } from "../../model/prompt/prompt";

type AgentContentProps = {
    title: string
    llms?: LLMSpecification[]
    prompts?: Prompt[]
    agent: Agent
    setAgent: Dispatch<SetStateAction<Agent>>
    onChangeAgent: (e: React.ChangeEvent<HTMLSelectElement>, agent: Agent, setAgent: Dispatch<React.SetStateAction<Agent>>) => void
}

const AgentContent: React.FC<AgentContentProps> = (props) => {
    const {title, llms, prompts, agent, setAgent, onChangeAgent} = props;
    const {data: prompt} = usePrompt(agent.promptId);
    const content = useMemo(() => {
        if (!prompt) return null
        const promptContent: PromptContentItem[] = prompt.content;
        return (
            <>
                {promptContent.map((item) => {
                    const {role, content} = item;
                    return (
                        <div key={role}
                             className="px-10">
                            <p className="text-lg my-2">{role}</p>
                            <textarea className="form-input h-[150px] text-gray-500 text-lg"
                                      value={content}
                                      readOnly={true}
                            />
                        </div>
                    )
                })}
            </>
        )
    }, [prompt])

    return (
        <div>
            <div className="px-2 pt-7 text-lg font-semibold">
                {title}
            </div>
            <div className="form-input-group flex px-7 pt-7">
                <label className="form-label min-w-[150px] text-lg">
                    LLM 키
                </label>
                <select id="llmId"
                        className="form-select w-full px-2 focus:outline-none bg-white"
                        value={agent.llmId}
                        onChange={(e) => {onChangeAgent(e, agent, setAgent)}}>
                    <option value="-">-</option>
                    {llms?.map((llm) => (
                        <option key={llm._id} value={llm._id}>{llm.key}</option>
                    ))}
                </select>
            </div>
            <div className="form-input-group flex px-7 pt-7">
                <label className="form-label min-w-[150px] text-lg">
                    프롬프트 키
                </label>
                <select id="promptId"
                        className="form-select w-full px-2 focus:outline-none bg-white"
                        value={agent.promptId}
                        onChange={(e) => {onChangeAgent(e, agent, setAgent)}}
                >
                    <option value="-">-</option>
                    {prompts?.map((prompt) => {
                        // if (prompt.llmType!== agent.llmType) return null
                        return (
                            <option key={prompt._id} value={prompt._id}>{prompt.key}</option>
                        )
                    })}
                </select>
            </div>
            <div>
                {content}
            </div>
        </div>
    )
}

export default AgentContent;

import {PromptType} from "../../../model/prompt/prompt";

export const AnalyzeQueryDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "query",
        description: "사용자 질의"
    },
    {
        key: "dialog_context_2",
        description: "이전 대화 질문과 답변(2턴)"
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
]

export const AnswerWithPassageDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "query",
        description: "사용자 질의"
    },
    {
        key: "dialog_context_2",
        description: "GPT 이전 대화 질문과 답변(2턴)"
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
    {
        key: "passage_result",
        description: "문서 검색 결과"
    },
    {
        key: "keywords_query",
        description: "질의 분석 keywords 결과"
    },
    {
        key: "paraphrased_query",
        description: "질의 분석 paraphrased 결과"
    },
    {
        key: "extended_query",
        description: "질의 분석 extended 결과"
    },
]

export const AdjustAnswerDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "query",
        description: "사용자 질의"
    },
    {
        key: "dialog_context_2",
        description: "GPT 이전 대화 질문과 답변(2턴)"
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
    {
        key: "passage_result",
        description: "문서 검색 결과"
    },
    {
        key: "mrc_prediction",
        description: "MRC 정답"
    },
    {
        key: "keywords_query",
        description: "질의 분석 keywords 결과"
    },
    {
        key: "paraphrased_query",
        description: "질의 분석 paraphrased 결과"
    },
    {
        key: "extended_query",
        description: "질의 분석 extended 결과"
    },
]

export const SummarizePassageDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
    {
        key: "language",
        description: "데이터 언어"
    },
    {
        key: "main_page",
        description: "첫페이지 텍스트"
    },
]

export const RecommendQuestionFromContextDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "query",
        description: "사용자 질의"
    },
    {
        key: "gpt_dialog_context_1",
        description: "GPT 이전 대화 질문과 답변(1턴)"
    },
    {
        key: "clova_dialog_context_1",
        description: "CLOVA 이전 대화 질문과 답변(1턴)"
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
]

export const RecommendQuestionFromMainPageDefaultVariable = [
    {
        key: "current_date",
        description: "현재 시간과 날짜",
    },
    {
        key: "company_name",
        description: "챗봇 이름(회사 이름)"
    },
    {
        key: "language",
        description: "데이터 언어"
    },
    {
        key: "main_page",
        description: "첫페이지 텍스트"
    },
]

export const defaultVariable = [
    {
        key: PromptType.ANALYZE_ENGLISH_QUERY,
        data: AnalyzeQueryDefaultVariable
    },
    {
        key: PromptType.ANALYZE_KOREAN_QUERY,
        data: AnalyzeQueryDefaultVariable
    },
    {
        key: PromptType.MRC_ANSWER,
        data: AdjustAnswerDefaultVariable
    },
    {
        key: PromptType.PASSAGE_ANSWER,
        data: AnswerWithPassageDefaultVariable
    },
    {
        key: PromptType.CONTEXT_QUESTION,
        data: RecommendQuestionFromContextDefaultVariable
    },
    {
        key: PromptType.WELCOME,
        data: SummarizePassageDefaultVariable
    },
    {
        key: PromptType.WELCOME_QUESTION,
        data: RecommendQuestionFromMainPageDefaultVariable
    },
]

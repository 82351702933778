import logRepository from "../../respsitory/workflowLogRepository";
import {useInfiniteQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Page} from "../index";
import {WorkflowLog, WorkflowLogStatus} from "@/model/Log";


const getData = async (page: number, size: number, groupId?: string, status?: WorkflowLogStatus) => {
    try {
        const {data} = await logRepository.paginate(page, size, groupId, status)
        return {
            content: data.result.content,
            pageable: {
                skip: page,
                limit: size,
                isLast: data.result.isLast
            }
        }
    } catch (e) {
        throw e;
    }
}

const LIMIT = 20
const useWorkflowLogs = (groupId?: string, status?: WorkflowLogStatus) => {
    return useInfiniteQuery<Page<WorkflowLog>, AxiosError>({
        queryKey: ["workflow", "logs", groupId, status],
        queryFn: async ({ pageParam = { skip: 1, limit: LIMIT }}) => getData(
            pageParam.skip, pageParam.limit, groupId, status
        ),
        refetchOnMount: true,
        useErrorBoundary: false,
        getNextPageParam: ({pageable}) => {
            if (pageable.isLast) {
                return undefined
            }

            return {
                skip: pageable.skip + 1,
                limit: pageable.limit,
            }
        }
    })
}

export default useWorkflowLogs;

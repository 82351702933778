import {
    ANTHROPIC_SUPPORTED_MODELS,
    AZURE_OPENAI_SUPPORTED_MODELS,
    LLM42_SUPPORTED_MODELS,
    LLMSpecification,
    LLMSpecificationBase,
    LLMSpecificationCreateRequest,
    LLMSpecificationUpdateRequest,
    LLMVendorType,
    META_SUPPORTED_MODELS,
    MODEL_MAX_OUTPUT_TOKENS,
    NAVER_SUPPORTED_MODELS,
    OPENAI_SUPPORTED_MODELS
} from "../../model/llmSpecification";
import React, {useEffect, useMemo, useRef, useState} from "react";
import useCreateLLMSpecification from "../../query/llm/useCreateLLMSpecification";
import useUpdateLLMSpecification from "../../query/llm/useUpdateLLMSpecification";
import useDeleteLLMSpecification from "../../query/llm/useDeleteLLMSpecification";
import _ from "lodash";
import llmSpecificationRepository from "../../respsitory/LLMSpecificationRepository";
import toast from "react-hot-toast";
import {useDialog} from "../../hook/useDialog";
import moment from "moment";
import LLMSpecificationHistoryModal, {LLM_HISTORY_MODAL_ID} from "./LLMSpecificationHistoryModal";
import useModal from "../../hook/useModal";
import {useQueryClient} from "@tanstack/react-query";
import {Slider} from "@/components/ui/slider";

type LLMSpecificationSettingFormProps = {
    specification?: LLMSpecification;
    copySpecification?: LLMSpecificationBase;
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: (specificationKey: string) => void;
    onClickCopySpecification: (specification: LLMSpecificationBase) => void;
}

export const SUPPORTED_VENDORS_WITH_MODELS = [
    {
        vendor: LLMVendorType.OPENAI,
        label: "OpenAI",
        models: OPENAI_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.LLM42,
        label: "LLM42",
        models: LLM42_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.AZURE_OPENAI,
        label: "Azure OpenAI",
        models: AZURE_OPENAI_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.NAVER,
        label: "NAVER",
        models: NAVER_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.META,
        label: "Meta",
        models: META_SUPPORTED_MODELS,
    },
    {
        vendor: LLMVendorType.ANTHROPIC,
        label: "Anthropic",
        models: ANTHROPIC_SUPPORTED_MODELS,
    },
]

const INIT_INVALID = {
    key: false,
    vendor: false,
    model: false,
}

const LLMSpecificationSettingForm: React.FC<LLMSpecificationSettingFormProps> = (props) => {
    const {specification, copySpecification, onCancel, onDelete, onSubmit, onClickCopySpecification} = props;

    const dialog = useDialog();
    const modal = useModal();
    const queryClient = useQueryClient();

    const {mutateAsync: createSpecification} = useCreateLLMSpecification();
    const {mutateAsync: updateSpecification} = useUpdateLLMSpecification();
    const {mutateAsync: deleteSpecification} = useDeleteLLMSpecification();

    const keyRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const modelRef = useRef<HTMLSelectElement>(null);

    const [invalid, setInvalid] = useState<{key: boolean, vendor: boolean, model: boolean}>(INIT_INVALID);

    const [selectedVendor, setSelectedVendor] = useState<LLMVendorType>();
    const [selectedModel, setSelectedModel] = useState<string>();
    const [temperature, setTemperature] = useState<number>(0);
    const [maxTokens, setMaxTokens] = useState<number>(1000);

    const supportedModels = useMemo(() => {
        return SUPPORTED_VENDORS_WITH_MODELS.find(item => item.vendor === selectedVendor)?.models ?? [];
    }, [selectedVendor]);

    const onChangeKey = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const key = e.target.value;
        if (key === specification?.key) return;
        llmSpecificationRepository.existed(key).then(({data}) => {
            setInvalid(prev => ({
                ...prev,
                key: data.result
            }))
        }).catch(() => {
            toast.error("중복을 체크하는 도중 에러가 발생했습니다.")
        })
    }, 200);

    const onChangeMaxTokens = (value: number[]) => {
        setMaxTokens(value[0])
    }

    const onChangeTemperature = (value: number) => {
        setTemperature(value)
    }

    const onChangeVendor = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedVendor(e.target.value as LLMVendorType);
    }

    const onChangeModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedModel(e.target.value);
    }

    const onClickDelete = () => {
        if (!specification) {
            return;
        }

        dialog.open({
            variant: "danger",
            title: "LLM 정보 삭제",
            content: "LLM 정보를 삭제하시겠습니까?",
            onConfirm: async () => {
                try {
                    await deleteSpecification(specification._id);
                    toast.success("LLM 정보를 삭제했습니다.");
                    onDelete();
                } catch (e: any) {
                    const statusCode = e.response.status
                    if (statusCode === 400) {
                        toast.error("현재 채널 설정 프리셋에서 사용중인 데이터입니다");
                    } else {
                        toast.error("LLM 정보를 삭제하는 도중 에러가 발생했습니다.");
                    }
                }
            }
        });

    }

    const onClickCancel = () => {
        onCancel();
    }

    const onClickSubmit = async () => {
        const key = keyRef.current?.value;
        const description = descriptionRef.current?.value;
        const model = modelRef.current?.value;

        const invalid = {
            key: !key,
            vendor: !selectedVendor,
            model: !model,
        }
        setInvalid(invalid);
        if (Object.values(invalid).some(v=>v)) {
            toast.error("빈칸을 채워주세요.")
            return;
        }

        if (specification) {
            const body: LLMSpecificationUpdateRequest = {
                key: key!,
                description,
                vendor: selectedVendor!,
                model: model!,
                maxTokens,
                temperature,
            }

            dialog.open({
                variant: "danger",
                title: `LLM 수정`,
                content: `LLM을 수정하려고 합니다. 계속 하시겠습니까?`,
                onConfirm: async () => {
                    try {
                        await updateSpecification({_id: specification._id, ...body});
                        toast.success("LLM 정보를 수정했습니다.")
                        await queryClient.invalidateQueries(["llmSpecificationHistory", specification._id], {exact: true});
                        onSubmit(specification.key);
                    } catch (e) {
                        toast.error("LLM 정보를 수정하는 도중 에러가 발생했습니다.")
                    }
                }
            });
        } else {
            const body: LLMSpecificationCreateRequest = {
                key: key!,
                description,
                vendor: selectedVendor!,
                model: model!,
                maxTokens,
                temperature,
            }
            try {
                await createSpecification(body);
                toast.success("LLM을 생성했습니다.")
                onSubmit(body.key);
            } catch (e) {
                toast.error("LLM을 생성하는 도중 에러가 발생했습니다.")
            }
        }
    }

    const modelMaxTokens = useMemo(() => {
        type ModelKey = keyof typeof MODEL_MAX_OUTPUT_TOKENS;
        return MODEL_MAX_OUTPUT_TOKENS[selectedModel as ModelKey];
    }, [selectedModel])

    const onClickShowHistoryModal = () => {
        modal.open(LLM_HISTORY_MODAL_ID);
    }

    const onClickCopy = async () => {
        if (!specification) return;
        const key = specification.key
        const currentDate = moment().format('YYYY-MM-DD HH:mm');
        const copiedKey = `${key}_copied(${currentDate})`
        const copySpecification = {...specification, key: copiedKey}
        onClickCopySpecification(copySpecification)
    }

    useEffect(() => {
        // 모델별로 최대 Max Tokens 길이 제한이 다름
        setMaxTokens(1000);
    }, [selectedModel, selectedVendor])

    useEffect(() => {
        setSelectedVendor(specification?.vendor ?? LLMVendorType.AZURE_OPENAI);
        setSelectedModel(specification?.model ?? "gpt-35-turbo-0125");
        setTemperature(specification?.temperature ?? 0);
        setMaxTokens(specification?.maxTokens ?? 1000);
        if(copySpecification) {
            setSelectedVendor(copySpecification.vendor);
            setSelectedModel(copySpecification.model);
        }
    }, [specification, copySpecification])

    return (
        <div>
            <div className="flex items-center justify-end pt-3 gap-x-2 mb-2">
                {specification &&
                    <div className="flex items-center justify-between space-x-2">
                        <button className="btn bg-purple-800 text-white transition-colors duration-200"
                                onClick={onClickShowHistoryModal}>
                            변경이력 보기
                        </button>
                        <LLMSpecificationHistoryModal LLMId={specification._id}/>
                        <button className="btn bg-green-500 text-white transition-colors duration-200"
                                onClick={onClickCopy}>
                            사본생성
                        </button>
                    </div>
                }
                <button className="btn btn-primary transition-colors duration-200" onClick={onClickSubmit}>
                    {specification ? "수정하기" : "저장하기"}
                </button>
                {specification &&
                    <button className="btn btn-danger transition-colors duration-200" onClick={onClickDelete}>
                        삭제하기
                    </button>
                }
                <button className="btn btn-secondary-outline transition-colors duration-200" onClick={onClickCancel}>
                    취소하기
                </button>
            </div>
            <div className="border border-gray-400 rounded-md shadow-lg p-5 flex flex-col overflow-y-auto">
                <div className="text-xl font-semibold mb-3 border-b-2 pb-3">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <div className={"flex"}>
                                <p>LLM 정보</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-key"
                           className="form-label min-w-[200px] text-lg">
                        LLM Key
                    </label>
                    <div className="w-full">
                        <input id="llm-key"
                               defaultValue={specification?.key ?? copySpecification?.key}
                               ref={keyRef}
                               onChange={onChangeKey}
                               className="form-input w-full focus:outline-none"
                        />
                        {invalid.key && <small className="text-red-500">이미 있는 key는 사용할 수 없습니다.</small>}
                    </div>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-description"
                           className="form-label min-w-[200px] text-lg">
                        Description
                    </label>
                    <input id="llm-description"
                           defaultValue={specification?.description ?? copySpecification?.description}
                           ref={descriptionRef}
                           className="form-input w-full focus:outline-none"
                    />
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-vendor"
                           className="form-label min-w-[200px] text-lg">
                        Vendor
                    </label>
                    <select id="llm-vendor"
                            className="form-select w-full px-2 focus:outline-none"
                            value={selectedVendor}
                            onChange={onChangeVendor}
                            disabled={!!specification}
                    >
                        {SUPPORTED_VENDORS_WITH_MODELS.map((item) => (
                            <option key={item.vendor} value={item.vendor}>{item.label}</option>
                        ))}
                    </select>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-model"
                           className="form-label min-w-[200px] text-lg">
                        Model
                    </label>
                    <select id="llm-model"
                            className="form-select w-full px-2 focus:outline-none"
                            ref={modelRef}
                            value={selectedModel}
                            onChange={onChangeModel}
                            disabled={!!specification}
                    >
                        {supportedModels.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>
                </div>

                <div className="form-input-group flex">
                    <label htmlFor="llm-model"
                           className="form-label min-w-[200px] text-lg">
                        Context Window Size
                    </label>
                    <input className="form-input" defaultValue={specification?.contextWindowSize} disabled={true}/>
                </div>

                <div className="form-input-group flex items-start">
                    <label className="form-label min-w-[200px] text-lg whitespace-pre-wrap">
                        {"Parameters"}
                    </label>

                    <div className="w-full">
                        <div className="space-y-[8px] pb-[8px]">
                            <div className="items-center space-y-[8px]">
                                <div className="flex justify-between text-[14px]">
                                    <div>
                                        Temperature
                                    </div>
                                    <div>
                                        {temperature}
                                    </div>
                                </div>
                                <Slider value={[temperature]} max={1} step={0.01} onValueChange={(value) => {onChangeTemperature(value[0])}}/>
                            </div>
                            <div className="items-center space-y-[8px]">
                                <div className="flex justify-between text-[14px]">
                                    <div>
                                        Max tokens
                                    </div>
                                    <div>
                                        {maxTokens}
                                    </div>
                                </div>
                                <Slider value={[maxTokens]} max={modelMaxTokens} step={10} onValueChange={onChangeMaxTokens}/>
                            </div>
                        </div>
                    </div>
                </div>

                {specification &&
                    <>
                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[200px] text-lg">
                                생성된 날짜
                            </label>
                            <p>{moment.utc(specification.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>

                        <div className="form-input-group flex items-center">
                            <label className="form-label min-w-[200px] text-lg">
                                수정된 날짜
                            </label>
                            <p>{moment.utc(specification.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default LLMSpecificationSettingForm;

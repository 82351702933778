import promptRepository from "../../respsitory/PromptRepository";
import {useQuery} from "@tanstack/react-query";
import {LLMType, Prompt, PromptType} from "../../model/prompt/prompt";
import {AxiosError} from "axios";

const getData = async (key?: string, promptType?: PromptType, llmType?: LLMType) => {
    try {
        const {data} = await promptRepository.find(key, promptType, llmType);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const usePrompts = (key?: string, promptType?: PromptType, llmType?: LLMType) => {
    return useQuery<Prompt[], AxiosError>({
        queryKey: ["prompt", key, promptType, llmType],
        queryFn: async () => getData(key, promptType, llmType),
        retry: false,
        suspense: false
    })
}

export default usePrompts;
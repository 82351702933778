import React, {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import QuestionTestButton from "../../component/LLMResponseTestPanel/LLMResponseTestPanel";
import classNames from "classnames";
import Tooltip from "../../component/Tooltip";
import useChannelCommonSetting from "../../query/channelCommonSetting/useChannelCommonSetting";
import {StarIcon} from "@heroicons/react/24/outline";
import useUpdateChannelStar from "../../query/channel/useUpdateChannelStar";
import moment from "moment";
import {ChannelResponse} from "@/model/channel/channel";
import {ChatBulkTestDialog} from "@/components/chatBulkTestDialog";
const ChannelRow: React.FC<{
    className?: string,
    channel: ChannelResponse,
    role?: string,
    redirect: () => void
}> = ({className, channel, redirect}) => {
    const intl = useIntl();

    const {data: channelCommonSettings} = useChannelCommonSetting();

    const channelCommonSettingKey = useMemo(() => {
        return channelCommonSettings?.filter(setting => setting._id === channel?.setting.channelCommonSettingId)[0]?.key
    }, [channelCommonSettings, channel])

    const onClickChannelName = (e: React.MouseEvent) => {
        e.preventDefault();
        redirect();
    };

    if (!channel) {return <div></div>;}
    return (
        <tr className={"h-16 text-sm text-black font-[350] overflow-auto relative border-b hover:bg-gray-100 " + className || ""}>
            <TooltipTd className={"p-2.5 whitespace-nowrap font-medium text-[14px]"}
                       textClassName={classNames('cursor-pointer truncate hover:text-blue-500 hover:underline', {'text-red-500': channel.deleted})}
                       onClick={onClickChannelName}
                       channelId={channel._id}
                       deleted={channel.deleted}
                       star={channel.star}>
                {channel.deleted
                    ? `${channel.name} (${intl.formatMessage({id: "i10023"})})`
                    : channel.name
                }
            </TooltipTd>
            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                {channel.description}
            </TooltipTd>
            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                {channelCommonSettingKey ?? <div className="text-red-500 font-medium">{intl.formatMessage({id: "i1006"})}</div>}
            </TooltipTd>
            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                {channel.channelSubscription?.plan.name}
            </TooltipTd>

            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                <div>
                    {channel.channelSubscription && intl.formatNumber(channel.channelSubscription.currentUsage.message)}/
                    {channel.channelSubscription && intl.formatNumber(channel.channelSubscription.plan.usageLimit.message + channel.channelSubscription.forwardedLimit.message)}</div>
                <div>
                    {
                        channel.channelSubscription?.forwardedLimit.message !== 0 && channel.channelSubscription &&
                        `(지난 요금제 잔여량: ${intl.formatNumber(channel.channelSubscription.forwardedLimit.message)})`
                    }
                </div>
            </TooltipTd>
            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                {channel.user?.fullName ?? <div className="text-red-500 font-medium">{intl.formatMessage({id: "i1006"})}</div>}
            </TooltipTd>
            {/*{role &&*/}
            {/*    <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>*/}
            {/*        {role}*/}
            {/*    </TooltipTd>*/}
            {/*}*/}
            <TooltipTd className={"p-2.5 whitespace-nowrap text-[14px]"}>
                {channel.deleted ?
                    <div>
                        <div>
                            생성 일자: {moment.utc(channel.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                        <div>
                            삭제 일자: {moment.utc(channel.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                    </div>
                    :
                    <div>
                        {moment.utc(channel.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                }
            </TooltipTd>
            <td className="p-2.5 whitespace-nowrap text-[14px]">
                {channel.deleted ?
                    (
                        channel.hardDeleted ?
                            <div className="text-red-500">영구 삭제된 채널</div>
                            :
                            <div className="text-red-500">삭제된 채널</div>
                    )
                    :
                    <>
                        <QuestionTestButton channelId={channel._id}/>
                        <ChatBulkTestDialog channel={channel}/>
                    </>
                }
            </td>
        </tr>
    );
};

interface TooltipTdProps {
    channelId?: string
    className?: string
    textClassName?: string
    children: React.ReactNode
    onClick?: (e: React.MouseEvent) => void
    deleted?: boolean
    star?: boolean
}

const TooltipTd: React.FC<TooltipTdProps> = (props) => {
    const {channelId, className, textClassName, children, onClick, deleted, star} = props;
    const {mutateAsync: channelUpdateStarRequest} = useUpdateChannelStar();

    const location = useLocation();
    const [important, setImportant] = useState<boolean>();
    const tdRef = useRef<HTMLTableDataCellElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [isTextEclipsed, setIsTextEclipsed] = useState<boolean>(false);

    const _className = useMemo(() => {
        return classNames("relative", {[className ?? '']: className});
    }, [className]);

    const _textClassName = useMemo(() => {
        return classNames("truncate", {[textClassName ?? '']: textClassName});
    }, [textClassName]);

    useEffect(() => {
        const textElem = textRef.current

        if (textElem) {
            setIsTextEclipsed(textElem.offsetWidth < textElem.scrollWidth);
        }
        if (star) {
            setImportant(star)
        } else {
            setImportant(false)
        }
    }, [location, star])

    const onClickContent = (e: React.MouseEvent) => {
        onClick && onClick(e);
    }

    const onClickImportant = async () => {
        if (channelId) {
            await channelUpdateStarRequest({channelId, star:!important});
            setImportant(!important)
        }
    }
    return (
        <td className={_className}
            ref={tdRef}>
            <div className="flex">
                {channelId !== undefined && !deleted &&
                    <div className="w-7 h-7">
                        <button onClick={onClickImportant}>
                            <StarIcon className={classNames("w-5 h-5", important && "fill-yellow-400")}/>
                        </button>
                    </div>

                }
                <div ref={textRef}
                     className={_textClassName}
                     onClick={onClickContent}>
                    {children}
                </div>
            </div>
            <Tooltip targetRef={tdRef}
                     content={children}
                     disabled={!isTextEclipsed} />
        </td>
    );
}

export default ChannelRow;

import promptRepository from "../../respsitory/PromptRepository";
import {useQuery} from "@tanstack/react-query";
import {Prompt} from "../../model/prompt/prompt";
import {AxiosError} from "axios";

const getData = async (promptId: string) => {
    try {
        const {data} = await promptRepository.read(promptId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const usePrompt = (promptId: string) => {
    return useQuery<Prompt, AxiosError>({
        enabled: !!promptId,
        queryKey: ["prompt", promptId],
        queryFn: async () => getData(promptId),
        retry: false,
        suspense: false
    })
}

export default usePrompt;

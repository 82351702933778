import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryAnswerRepository, { TestSetting } from "../../respsitory/QueryAnswerRepository";
import { SiteBunnyAdminError, toSiteBunnyAdminError } from "../../error";
import {QueryLog} from "../../model/QueryLog";


const useSendQuery = () => {
    const queryClient = useQueryClient();

    return useMutation<QueryLog, SiteBunnyAdminError, {query: string, contextId: string, testSetting: TestSetting}>({
        mutationKey: ["test", "query"],
        mutationFn: async (queryForm) => {
            try {
                const { data } = await queryAnswerRepository.sendTestQuery(queryForm.query, queryForm.contextId, queryForm.testSetting);
                return data.result;
            } catch (e) {
                throw toSiteBunnyAdminError(e);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["test", "query"], {exact: true});
        }
    })
};

export default useSendQuery;
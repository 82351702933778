"use client"

import * as React from "react"
import {useMemo} from "react"
import {ResizableHandle, ResizablePanel, ResizablePanelGroup} from "@/components/ui/resizable";
import {Tabs, TabsContent} from "@/components/ui/tabs";
import {TooltipProvider} from "@/components/ui/tooltip";
import {WorkflowLog} from "@/model/Log";
import {OperatorOutletDetail} from "@/view/workflowLog/operator-outlet-detail";
import {OperatorOutletList} from "@/view/workflowLog/operatorOutletList";
import classNames from "classnames";

interface MailProps {
    className?: string
    log: WorkflowLog
    defaultLayout: number[] | undefined
}

export function WorkflowLogViewer({
                                      className,
                                      log,
                                      defaultLayout = [52, 48],
                                  }: MailProps) {
    const [operatorName, setOperatorName] = React.useState<string | undefined>(undefined)
    // const [mail] = useMail()

    const selectedOperatorOutlet = useMemo(() => {
        return log.operatorOutlets.find((item) => item.operatorName === operatorName) || null
    }, [log.operatorOutlets, operatorName])
    return (
        <TooltipProvider delayDuration={0}>
            <ResizablePanelGroup
                direction="horizontal"
                onLayout={(sizes: number[]) => {
                    if (!selectedOperatorOutlet) return

                    localStorage.setItem("react-resizable-panels:layout:mail", JSON.stringify(
                        sizes
                    ))
                }}
                className={classNames("h-full max-h-[800px] items-stretch flex flex-col", className)}
            >
                <ResizablePanel defaultSize={defaultLayout[0]} minSize={30}>
                    <Tabs defaultValue="all" className='h-full'>
                        <TabsContent value="all" className="m-0 h-full">
                            <OperatorOutletList items={log.operatorOutlets}
                                                params={log.params}
                                                setOperatorName={setOperatorName}
                                                selectedOperatorName={operatorName}/>
                        </TabsContent>
                        <TabsContent value="unread" className="m-0">
                            <OperatorOutletList
                                params={log.params}
                                items={log.operatorOutlets.filter((item) => item.type !== 'failed')}
                                setOperatorName={setOperatorName}
                                selectedOperatorName={operatorName}
                            />
                        </TabsContent>
                    </Tabs>
                </ResizablePanel>
                {selectedOperatorOutlet &&
                    <>
                        <ResizableHandle withHandle/>
                        <ResizablePanel defaultSize={defaultLayout[1]} minSize={0} collapsible={true}>
                            <OperatorOutletDetail operatorOutlet={selectedOperatorOutlet}/>
                        </ResizablePanel>
                    </>

                }

            </ResizablePanelGroup>
        </TooltipProvider>
    )
}
import {useQuery} from "@tanstack/react-query";
import channelRepository from "../../respsitory/ChannelRepository";
import {ChannelRead} from "@/model/channel/channel";

const getData = async (channelId: string) => {
    try {
        const { data } = await channelRepository.read(channelId);
        return data.result;
    } catch (e) {
        throw e;
    }
}

const useChannel = (channelId?: string) => {
    return useQuery<ChannelRead, Error>({
        enabled: !!channelId,
        queryKey: ['channel', channelId],
        queryFn: async () => getData(channelId!),
        retry: false,
        suspense: false,
    })
}

export default useChannel;

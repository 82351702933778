import axios from "axios";
import {WOKRFLOW_API_HOST} from "@/core/variables";
import {WorkflowLogStatus} from "@/model/Log";

class WorkflowJobRepository {
    async paginate(page: number, size: number, groupId?: string, status?: WorkflowLogStatus) {
        console.log(WOKRFLOW_API_HOST)
        return axios.get(`${WOKRFLOW_API_HOST}/v1/results`, {
            params: {page, size, groupId, status}
        })
    };


}
const jobRepository = new WorkflowJobRepository();
export default jobRepository

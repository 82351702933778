import {CheckIcon, DocumentDuplicateIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useList} from "@react-hookz/web";
import {useDialog} from "../../../hook/useDialog";
import {useIntl} from "react-intl";
import * as uuid from "uuid";
import {ChannelResponse, ChannelSettingUpdate, ChannelVariableList} from "../../../model/channel/channel";
import React, {useCallback, useEffect, useState} from "react";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger,} from "@/components/ui/sheet"
import toast from "react-hot-toast";

type ChannelSettingVariableViewProps = {
    channel: ChannelResponse
    settingRef: React.MutableRefObject<ChannelSettingUpdate>
    channelParamRefs: React.MutableRefObject<{ key: HTMLInputElement | null, value: HTMLInputElement | null }[]>
}
const REQUIRED_CHANNEL_VARIABLE_KEYS = [
    "companyName"
]


const ChannelSettingVariableView: React.FC<ChannelSettingVariableViewProps> = (props) => {
    const {channel, settingRef, channelParamRefs} = props;
    const intl = useIntl();
    const dialog = useDialog();

    const [companyName, setCompanyName] = useState<string>(channel.setting.companyName);
    const [channelVariables, {
        set: setChannelVariables,
        push: pushChannelVariables,
        removeAt: removeAtChannelVariables,
    }] = useList<{ id: string, key?: string, value?: any }>([]);

    const deleteVariableByIndex = useCallback((index: number) => {
        channelParamRefs.current = [];
        removeAtChannelVariables(index);
    }, [channelParamRefs, removeAtChannelVariables]);

    const onClickDeleteVariable = (index: number) => {
        const key = channelVariables?.[index]?.key;
        if (key && REQUIRED_CHANNEL_VARIABLE_KEYS.includes(key)) {
            dialog.open({
                title: intl.formatMessage({id: "i0168"}),
                content: `${key} ${intl.formatMessage({id: "i0215"})}`,
                variant: "danger",
                onConfirm: () => {
                    deleteVariableByIndex(index);
                },
                confirmText: intl.formatMessage({id: "i0170"}),
            })
        } else {
            deleteVariableByIndex(index);
        }
    }
    const onClickAddVariable = () => {
        pushChannelVariables({id: uuid.v4()})
    }

    const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyName(e.target.value);
        settingRef.current = ({
            ...settingRef.current,
            companyName: e.target.value
        })
    }

    useEffect(() => {
        if (channel.setting.variables) {
            setChannelVariables(Object.entries(channel.setting.variables)
                .map(([key, value]) => ({id: uuid.v4(), key, value})))
        }
    }, [channel, setChannelVariables, pushChannelVariables])


    return (
        <div className="w-full">
            <div className="flex items-center space-x-2">
                <input className="form-input" defaultValue={"company_name"} readOnly={true}/>
                <input className="form-input" value={companyName} onChange={onChangeCompanyName}/>
                <div className="w-12 h-12"></div>
            </div>

            {channelVariables.map((variable, index) => {
                return (
                    <div key={variable.id}
                         className="flex items-center space-x-2">
                        <input className="form-input"
                               defaultValue={variable.key}
                               placeholder="채널 변수 키"
                               ref={(el: HTMLInputElement) => {
                                   if (el) {
                                       if (!channelParamRefs.current[index]) {
                                           channelParamRefs.current[index] = {key: null, value: null};
                                       }
                                       channelParamRefs.current[index].key = el;
                                   }
                               }}/>
                        <input className="form-input"
                               defaultValue={variable.value}
                               placeholder="채널 변수 값"
                               ref={(el: HTMLInputElement) => {
                                   if (el) {
                                       if (!channelParamRefs.current[index]) {
                                           channelParamRefs.current[index] = {key: null, value: null};
                                       }
                                       channelParamRefs.current[index].value = el;
                                   }
                               }}/>
                        <TrashIcon className="w-12 h-12 cursor-pointer"
                                   onClick={() => onClickDeleteVariable(index)}/>
                    </div>
                )
            })}
            <button className="mt-2 btn btn-secondary-outline transition-colors duration-200 btn-sm w-full mb-[10px]"
                    onClick={onClickAddVariable}>
                {intl.formatMessage({id: "i0195"})}
            </button>
            <ChannelVariablesList/>
        </div>
    )
}


const ChannelVariablesList = () => {
    const [copiedKey, setCopiedKey] = useState<string>();

    const handleCopyClipBoard = useCallback((text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedKey(text)
        toast.success('변수명이 복사 되었습니다.');
    }, [])

    return (
        <Sheet>
            <SheetTrigger className="border-2 rounded-[8px] p-[8px]">변수 목록 보기</SheetTrigger>
            <SheetContent side="left">
                <SheetHeader>
                    <SheetTitle>변수 목록</SheetTitle>
                    {
                        ChannelVariableList.map((item) => {
                            const {key, description} = item;
                            return (
                                <div className="flex items-center">
                                    <button
                                        className="border-2 rounded-2xl w-[50%] p-2 duration-200 hover:border-black mr-2"
                                        onClick={() => handleCopyClipBoard(key)}
                                    >
                                        <div className="flex items-center justify-between ml-2">
                                            {key}
                                            <div>
                                                {copiedKey !== key
                                                    ? <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"/>
                                                    : <CheckIcon className="w-5 h-5 text-green-500"/>
                                                }
                                            </div>
                                        </div>
                                    </button>
                                    <SheetDescription>
                                        {description}
                                    </SheetDescription>
                                </div>

                            )
                        })
                    }
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}
export default ChannelSettingVariableView;

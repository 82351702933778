import {User} from "../User";
import {DictType, HardDeletionMarkable} from "../index";
import {ChannelSubscriptionSummary} from "../subscription/channelSubscription";
import {RoleSummary} from "../memberSummary";
import { ChannelCard } from "../transaction/transaction";

export enum Language {
    KOREAN = 'Korean',
    ENGLISH = 'English'
}
export const ChannelVariableList = [
    {
        key: "en_external_source_notice_message",
        description: "영어_외부 데이터 출처 사용 경고 메시지"
    },
    {
        key: "ko_external_source_notice_message",
        description: "한글_외부 데이터 출처 사용 경고 메시지"
    },
]

export interface ChannelSetting {
    channelCommonSettingId: string;
    companyName: string;
    externalSourceNoticeMessage?: string;
    language: Language;
    qa42ProjectId?: string;
    showPassageSearchFailMessage: boolean;
    showReference: boolean;
    showQuestionReply: boolean;
    variables: DictType<any>;
}

export interface ChannelSettingUpdate {
    channelCommonSettingId?: string;
    companyName?: string;
    externalSourceNoticeMessage?: string;
    showPassageSearchFailMessage?: boolean;
    showReference?: boolean;
    showQuestionReply?: boolean;
    variables?: DictType<any>;
}


interface ChannelBase {
    name: string;
    description: string;
}

export interface ChannelOnboardingStepStatus {
    websiteUrl: string
    web_source_name: string
    welcome_message: string
}

export interface Channel extends ChannelBase {
    _id: string;
    scenarioId: string;
    setting: ChannelSetting;
    star: boolean;
    groupId?: string;
    onboardingStepStatus?: ChannelOnboardingStepStatus;
    indexId: string;
}

export interface ChannelResponse extends Channel, HardDeletionMarkable {
    _id: string;
    setting: ChannelSetting;
    deleted?: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string

    user?: User
    channelSubscription?: ChannelSubscriptionSummary
    role?: RoleSummary
    card?: ChannelCard
}

export interface ChannelPage {
    channels: ChannelResponse[],
    count: number
    totalCount: number
}

export interface ChannelRead {
    channel: ChannelResponse
}
